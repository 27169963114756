import { FC } from 'react';

import ContentCutIcon from '@mui/icons-material/ContentCut';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

import { EditorTool } from 'common/pages/EditorPage/models/types';

interface Props extends SvgIconProps {
  tool: EditorTool;
}

export const ToolIcon: FC<Props> = ({ tool, ...props }) => {
  switch (tool) {
    case 'upload':
      return <UploadFileIcon {...props} />;
    case 'trim':
      return <ContentCutIcon {...props} />;
    default:
      return null;
  }
};
