import { Locale } from './i18n.config';

const globalDictionaries = {
  bn: async () => await import('./translations/global/bn.json').then(module => module.default),
  cs: async () => await import('./translations/global/cs.json').then(module => module.default),
  da: async () => await import('./translations/global/da.json').then(module => module.default),
  de: async () => await import('./translations/global/de.json').then(module => module.default),
  el: async () => await import('./translations/global/el.json').then(module => module.default),
  en: async () => await import('./translations/global/en.json').then(module => module.default),
  es: async () => await import('./translations/global/es.json').then(module => module.default),
  fi: async () => await import('./translations/global/fi.json').then(module => module.default),
  fil: async () => await import('./translations/global/fil.json').then(module => module.default),
  fr: async () => await import('./translations/global/fr.json').then(module => module.default),
  hi: async () => await import('./translations/global/hi.json').then(module => module.default),
  id: async () => await import('./translations/global/id.json').then(module => module.default),
  it: async () => await import('./translations/global/it.json').then(module => module.default),
  ja: async () => await import('./translations/global/ja.json').then(module => module.default),
  ko: async () => await import('./translations/global/ko.json').then(module => module.default),
  ms: async () => await import('./translations/global/ms.json').then(module => module.default),
  nb: async () => await import('./translations/global/nb.json').then(module => module.default),
  nl: async () => await import('./translations/global/nl.json').then(module => module.default),
  pl: async () => await import('./translations/global/pl.json').then(module => module.default),
  pt: async () => await import('./translations/global/pt.json').then(module => module.default),
  ro: async () => await import('./translations/global/ro.json').then(module => module.default),
  ru: async () => await import('./translations/global/ru.json').then(module => module.default),
  sv: async () => await import('./translations/global/sv.json').then(module => module.default),
  th: async () => await import('./translations/global/th.json').then(module => module.default),
  tr: async () => await import('./translations/global/tr.json').then(module => module.default),
  uk: async () => await import('./translations/global/uk.json').then(module => module.default),
  vi: async () => await import('./translations/global/vi.json').then(module => module.default),
  'zh-hans': async () => await import('./translations/global/zh-hans.json').then(module => module.default),
  'zh-hant': async () => await import('./translations/global/zh-hant.json').then(module => module.default)
};

export const getGlobalTranslate = async (locale: Locale) => {
  const dictionary = globalDictionaries[locale];

  return await dictionary();
};

export type GlobalTranslate = Awaited<ReturnType<typeof getGlobalTranslate>>;

export const getAllGlobalTranslations = async () => {
  const da = await getGlobalTranslate('da');
  const de = await getGlobalTranslate('de');
  const en = await getGlobalTranslate('en');
  const es = await getGlobalTranslate('es');
  const fr = await getGlobalTranslate('fr');
  const it = await getGlobalTranslate('it');
  const nl = await getGlobalTranslate('nl');
  const pt = await getGlobalTranslate('pt');
  const tr = await getGlobalTranslate('tr');

  return { da, de, en, es, fr, it, nl, pt, tr };
};
