'use client';

import { FC, useContext } from 'react';

import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { useSubscription } from 'common/hooks/useSubscription';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';
import { EditorTool } from 'common/pages/EditorPage/models/types';

import { ToolIcon } from './ToolIcon';

interface Props {
  tool: EditorTool;
}

export const MenuItem: FC<Props> = ({ tool }) => {
  const editorModel = useContext(EditorModelContext);
  const isToolsMenuOpened = useSubscription(editorModel.isToolsMenuOpened);
  const activeTool = useSubscription(editorModel.activeTool);
  const label = editorModel.getToolLabel(tool);

  const handleClick = () => {
    editorModel.setTool(tool);
  };

  const isSelected = activeTool === tool;

  return (
    <ListItem key={tool} sx={{ display: 'block' }} disablePadding>
      <ListItemButton
        selected={isSelected}
        sx={{
          justifyContent: isToolsMenuOpened ? 'initial' : 'center',
          minHeight: 48,
          px: 2.5
        }}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{
            justifyContent: 'center',
            minWidth: 0,
            mr: isToolsMenuOpened ? 3 : 'auto'
          }}
        >
          <ToolIcon sx={{ color: isSelected ? '#000' : 'inherit' }} tool={tool} />
        </ListItemIcon>
        <ListItemText
          primary={label}
          sx={{ '& > span': { fontWeight: isSelected ? 'bold' : 'normal' }, opacity: isToolsMenuOpened ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  );
};
