import * as React from 'react';
import { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const TrimSettings: FC = () => (
  <Box sx={{ p: 2, textAlign: 'center' }}>
    <Typography sx={{ mt: 4 }} variant="h3">
      Trim
    </Typography>
  </Box>
);
