import * as React from 'react';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { ToolsMenu } from 'common/pages/EditorPage/components/ToolsMenu/ToolsMenu';

import { CenterBox, TimelineBox, ToolsSettingsBox, WrapperBox } from './components';
import { ContentBox } from './ContentBox/ContentBox';

interface Props extends PropsWithChildren {
  readonly header: ReactNode;
  readonly timeline: ReactNode;
  readonly toolsSettings: ReactNode;
}

export const EditorLayout: FC<Props> = ({ children, header, timeline, toolsSettings }) => (
  <>
    {header}
    <WrapperBox>
      <ToolsMenu>
        <CenterBox>
          <ToolsSettingsBox>{toolsSettings}</ToolsSettingsBox>
          <ContentBox>{children}</ContentBox>
        </CenterBox>
      </ToolsMenu>
    </WrapperBox>
    <TimelineBox>{timeline}</TimelineBox>
  </>
);
