export const getBase64FromFile = async (file: File): Promise<string> =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(`${reader.result}`);
    };

    reader.onerror = error => {
      reject(error);
    };
  });
