import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';

export const WrapperBox: FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => (
  <Box sx={{ mt: 8 }} {...props}>
    {children}
  </Box>
);

export const CenterBox: FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => (
  <Box sx={{ display: 'flex' }} {...props}>
    {children}
  </Box>
);

export const ToolsSettingsBox: FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => (
  <Box
    sx={{
      background: 'rgba(33, 150, 243, 0.08)',
      borderRight: '1px solid #ccc',
      display: 'flex',
      justifyContent: 'center',
      width: { lg: 400, md: 250 }
    }}
    {...props}
  >
    {children}
  </Box>
);

export const TimelineBox: FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => (
  <Box
    sx={{
      background: '#fff',
      borderTop: '1px solid #ccc',
      bottom: 0,
      height: 190,
      left: 0,
      position: 'absolute',
      width: '100%',
      zIndex: 10001
    }}
    {...props}
  >
    {children}
  </Box>
);
