import { getCookie } from 'cookies-next';

import { Undefined } from 'common/lib/types/assert';

import { LOCALE_COOKIE_KEY } from '../const/cookies.const';

import { Locale } from './i18n.config';
import { getLocaleFromOptionalString, isDefaultLanguage } from './locale.libs.ts';

export const processLocale = (currentLocale: Undefined<string>) => {
  const locale: Locale = getLocaleFromOptionalString(currentLocale);
  const isDefault = isDefaultLanguage(locale);
  const rootUrl = isDefault ? '/' : `/${locale}`;

  return {
    isDefaultLanguage: isDefault,
    locale,
    rootUrl
  };
};

export const getCurrentLocale = () => {
  const localeStr = getCookie(LOCALE_COOKIE_KEY);

  return processLocale(localeStr);
};
