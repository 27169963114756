import * as React from 'react';
import { FC } from 'react';

import Box from '@mui/material/Box';

import { PlayPauseButton } from './PlayPauseButton/PlayPauseButton';

export const Timeline: FC = () => (
  <Box sx={{ p: 2, textAlign: 'center' }}>
    <PlayPauseButton sx={{ mt: 2 }} />
  </Box>
);
