import { createContext } from 'react';

import { Atom } from '@grammarly/focal';

import { createPlayerModel, PlayerModel } from './PlayerModel';
import { EditorTool, VideoFile } from './types';

export interface EditorState {
  readonly activeTool: EditorTool;
  readonly files: VideoFile[];
  readonly isToolsMenuOpened: boolean;
  readonly tools: EditorTool[];
  readonly toolsLabels: Record<EditorTool, string>;
}

export class EditorModel {
  public readonly tools: Atom<EditorTool[]>;
  public readonly activeTool: Atom<EditorTool>;
  public readonly isToolsMenuOpened: Atom<boolean>;
  public readonly files: Atom<VideoFile[]>;

  public readonly player: PlayerModel;

  constructor(public readonly state: Atom<EditorState>) {
    this.tools = this.state.lens('tools');
    this.activeTool = this.state.lens('activeTool');
    this.isToolsMenuOpened = this.state.lens('isToolsMenuOpened');
    this.files = this.state.lens('files');

    this.player = createPlayerModel();
  }

  setTool(tool: EditorTool) {
    this.state.modify(state => ({ ...state, activeTool: tool }));
  }

  toggleToolsMenu() {
    this.state.modify(state => ({ ...state, isToolsMenuOpened: !state.isToolsMenuOpened }));
  }

  getToolLabel(tool: EditorTool) {
    return this.state.get().toolsLabels[tool];
  }

  setFiles(files: VideoFile[]) {
    this.state.modify(state => ({ ...state, files }));

    this.player.setVideo(files[0] ?? null);
  }
}

const DEFAULT_EDITOR_STATE: EditorState = {
  activeTool: 'upload',
  files: [],
  isToolsMenuOpened: true,
  tools: ['upload', 'trim'],
  toolsLabels: {
    trim: '{Trim}',
    upload: '{Upload}'
  }
};

export const createEditorModel = (state: Partial<EditorState> = {}) =>
  new EditorModel(Atom.create({ ...DEFAULT_EDITOR_STATE, ...state }));

export const EditorModelContext = createContext<EditorModel>(createEditorModel());
