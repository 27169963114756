'use client';

import { FC } from 'react';

import { Button } from '@mui/material';

import { useRouter } from 'common/hooks/useRouter';
import { Locale } from 'common/i18n/i18n.config';
import { PATHS } from 'common/routes/PATHS';

interface Props {
  label: string;
  lang: Locale;
}

export const UploadVideoButton: FC<Props> = ({ label, lang }) => {
  const router = useRouter(lang);

  const handleClick = () => {
    void router.push(PATHS.Editor[lang]);
  };

  return (
    <Button sx={{ pl: 10, pr: 10 }} variant="contained" onClick={handleClick}>
      {label}
    </Button>
  );
};
