'use client';

import { useContext, useEffect } from 'react';

import { useSubscription } from 'common/hooks/useSubscription';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

export const useVideoStatus = () => {
  const editorModel = useContext(EditorModelContext);
  const videoElement = useSubscription(editorModel.player.videoElement);

  useEffect(() => {
    if (!videoElement) {
      return;
    }

    const play = () => {
      editorModel.player.play();
    };

    const pause = () => {
      editorModel.player.pause();
    };

    videoElement.addEventListener('play', play, true);
    videoElement.addEventListener('pause', pause, true);

    return () => {
      videoElement.removeEventListener('play', play, true);
      videoElement.removeEventListener('pause', pause, true);
    };
  }, [videoElement, editorModel.player]);
};
