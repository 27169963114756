'use client';

import * as React from 'react';
import { FC, useCallback, useContext } from 'react';

import Box from '@mui/material/Box';

import { useSubscription } from 'common/hooks/useSubscription';
import { useVideoStatus } from 'common/pages/EditorPage/hooks/useVideoStatus';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

import { VideoElement } from './VideoElement/VideoElement';

export const Canvas: FC = () => {
  const editorModel = useContext(EditorModelContext);
  const movie = useSubscription(editorModel.player.video);

  useVideoStatus();

  const handleVideoLoad = useCallback((videoElement: HTMLVideoElement) => {
    // eslint-disable-next-line no-console
    console.log('videoElement', videoElement);
  }, []);

  if (!movie) {
    return <Box sx={{ background: 'yellow', border: '1px solid red', height: 480, width: 600 }} />;
  }

  return <VideoElement fileType={movie.file.type} src={movie.base64} onLoad={handleVideoLoad} />;
};
