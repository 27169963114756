'use client';

import { getCookie } from 'cookies-next';

import { LOCALE_COOKIE_KEY } from 'common/const/cookies.const';

import { processLocale } from './getCurrentLocale';

export const useLocale = () => {
  const localeStr = getCookie(LOCALE_COOKIE_KEY);

  return processLocale(localeStr);
};
