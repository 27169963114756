'use client';

import { FC, useContext } from 'react';

import { List } from '@mui/material';

import { useSubscription } from 'common/hooks/useSubscription';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

import { MenuItem } from './components/MenuItem';

export const MenuList: FC = () => {
  const editorModel = useContext(EditorModelContext);
  const tools = useSubscription(editorModel.tools);

  return (
    <List>
      {tools.map(tool => (
        <MenuItem key={tool} tool={tool} />
      ))}
    </List>
  );
};
