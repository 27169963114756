import { FC, PropsWithChildren, useCallback, useContext } from 'react';

import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';

import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

export const ContentBox: FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => {
  const editorModel = useContext(EditorModelContext);

  const setRef = useCallback(
    (element: HTMLDivElement) => {
      editorModel.player.setContentElement(element);
    },
    [editorModel.player]
  );

  return (
    <Box
      data-testid="ContentBox"
      ref={setRef}
      sx={{
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        minHeight: 'calc(100vh - 60px - 190px)'
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
