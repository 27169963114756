'use client';

import { useEffect, useState } from 'react';

import { Atom } from '@grammarly/focal';

export const useSubscription = <T>(model: Atom<T>) => {
  const [state, setState] = useState<T>(model.get());

  useEffect(() => {
    const subscription = model.subscribe(setState);

    return () => subscription.unsubscribe();
  }, [model]);

  return state;
};
