import { useCallback, useContext } from 'react';

import { useDropzone } from 'react-dropzone';

import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';
import { getBase64FromFile } from 'common/services/files.service';

export const useUploadSettings = () => {
  const editorModel = useContext(EditorModelContext);

  const onDrop = useCallback(
    async (raw: File[]) => {
      const files = await Promise.all(
        raw.map(async file => ({
          base64: await getBase64FromFile(file),
          file
        }))
      );
      editorModel.setFiles(files);
    },
    [editorModel]
  );

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: {
      'video/*': []
    },
    // @ts-ignore
    onDrop
  });

  return { getInputProps, getRootProps, isDragActive };
};
