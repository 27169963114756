'use client';

import * as React from 'react';
import { FC } from 'react';

import { useVideoElement } from './useVideoElement';

interface Props {
  fileType: string;
  onLoad: (video: HTMLVideoElement) => void;
  src: string;
}

export const VideoElement: FC<Props> = ({ fileType, onLoad, src }) => {
  const { setRef } = useVideoElement(onLoad);

  return (
    <video data-testid="VideoElement" ref={setRef}>
      <source src={src} type={fileType} />
    </video>
  );
};
