import { i18n } from 'common/i18n/i18n.config';

const getAll = (value: string) =>
  ({
    bn: value,
    cs: value,
    da: value,
    de: value,
    el: value,
    en: value,
    es: value,
    fi: value,
    fil: value,
    fr: value,
    hi: value,
    id: value,
    it: value,
    ja: value,
    ko: value,
    ms: value,
    nb: value,
    nl: value,
    pl: value,
    pt: value,
    ro: value,
    ru: value,
    sv: value,
    th: value,
    tr: value,
    uk: value,
    vi: value,
    'zh-hans': value,
    'zh-hant': value
  }) as const;

export const PATHS = {
  Editor: {
    ...getAll('/editor')
  },
  Home: {
    ...getAll('/')
  },
  PrivacyPolicy: {
    ...getAll('/privacy-policy')
  },
  TermsOfUse: {
    ...getAll('/terms-of-use')
  },
  Tools: {
    bn: '/tools',
    cs: '/tools',
    da: '/vaerktoejer',
    de: '/werkzeuge',
    el: '/ergaleia',
    en: '/tools',
    es: '/herramientas',
    fi: '/tyokalut',
    fil: '/mga-tool',
    fr: '/outils',
    hi: '/tools',
    id: '/alat',
    it: '/strumenti',
    ja: '/tools',
    ko: '/tools',
    ms: '/alat',
    nb: '/alat',
    nl: '/tools',
    pl: '/narzedzia',
    pt: '/ferramentas',
    ro: '/instrumente',
    ru: '/instrumenty',
    sv: '/verktyg',
    th: '/tools',
    tr: '/araclar',
    uk: '/instrumenty',
    vi: '/cong-cu',
    'zh-hans': '/tools',
    'zh-hant': '/tools'
  },
  VideoTrimmer: {
    bn: '/video-trimmer',
    cs: '/video-trimmer',
    da: '/videoklipper',
    de: '/video-schneiden',
    el: '/perikopi-vinteo',
    en: '/video-trimmer',
    es: '/cortar-video-online',
    fi: '/leikkaa-video',
    fil: '/video-trimmer',
    fr: '/couper-une-video',
    hi: '/video-trimmer',
    id: '/pemotong-video-online',
    it: '/tagliare-video-online',
    ja: '/video-trimmer',
    ko: '/video-trimmer',
    ms: '/pemotong-video',
    nb: '/videoklipper',
    nl: '/video-knippen',
    pl: '/przycinanie-filmow',
    pt: '/cortar-video',
    ro: '/taietor-video',
    ru: '/obrezat-video',
    sv: '/videoklippare',
    th: '/video-trimmer',
    tr: '/video-kesici',
    uk: '/obrizaty-video-onlayn',
    vi: '/cat-video-online',
    'zh-hans': '/video-trimmer',
    'zh-hant': '/video-trimmer'
  }
};

i18n.locales.forEach(key => {
  PATHS.VideoTrimmer[key] = `${PATHS.Tools[key]}${PATHS.VideoTrimmer[key]}`;
});
