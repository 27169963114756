import { Optional } from 'common/lib/types/assert';

import { i18n, Locale } from './i18n.config';

export const getLocaleFromString = (input: string): Locale => {
  const { locales } = i18n;

  return locales.includes(input as Locale) ? (input as Locale) : i18n.defaultLocale;
};

export const getLocaleFromOptionalString = (input: Optional<string>): Locale =>
  input ? getLocaleFromString(input) : i18n.defaultLocale;

export const isDefaultLanguage = (locale: Locale) => locale === i18n.defaultLocale;

export const getLocaleFromPathname = (pathname: string): Locale => {
  const [, locale] = pathname.split('/');

  return getLocaleFromString(locale);
};

export const getPathnameWithoutLocale = (pathname: string): string => {
  const locale = getLocaleFromPathname(pathname);

  return pathname.replace(`/${locale}`, '');
};
