import { useContext, useEffect } from 'react';

import { useSubscription } from 'common/hooks/useSubscription';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

export const useResizeVideo = () => {
  const editorModel = useContext(EditorModelContext);
  const contentElement = useSubscription(editorModel.player.contentElement);
  const videoElement = useSubscription(editorModel.player.videoElement);

  useEffect(() => {
    if (!videoElement || !contentElement) {
      return;
    }

    const handleResize = () => {
      let videoWidth: number;
      let videoHeight: number;

      if (videoElement.videoWidth > videoElement.videoHeight) {
        videoWidth = Math.round(contentElement.clientWidth * 0.8);
        videoHeight = Math.round(videoElement.videoHeight * (videoWidth / videoElement.videoHeight));
      } else {
        videoHeight = Math.round(contentElement.clientHeight * 0.8);
        videoWidth = Math.round(videoElement.videoWidth * (videoHeight / videoElement.videoWidth));
      }

      videoElement.style.width = `${videoWidth}px`;
      videoElement.style.height = `${videoHeight}px`;
    };

    handleResize();

    window.addEventListener('resize', handleResize, true);

    return () => {
      window.removeEventListener('resize', handleResize, true);
    };
  }, [contentElement, videoElement]);
};
