import { useRouter as useNextRouter } from 'next/navigation';

import { i18n, Locale } from 'common/i18n/i18n.config';

export const useRouter = (lang: Locale) => {
  const router = useNextRouter();

  const push = (path: string) => router.push(lang === i18n.defaultLocale ? path : `/${lang}${path}`);

  return { push };
};
